import 'react-native-gesture-handler';

import { RootNavigation } from 'navigation';
import { ModalProvider, ReactQueryProvider, StoreProvider } from 'providers';
import { SafeAreaProvider, SafeAreaView, initialWindowMetrics } from 'react-native-safe-area-context';
import * as SentryNative from '@sentry/react-native';


const Root = () => {
    return (
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
            <ReactQueryProvider>
                <StoreProvider>
                    <ModalProvider>
                        <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
                            <RootNavigation />
                        </SafeAreaView>
                    </ModalProvider>
                </StoreProvider>
            </ReactQueryProvider>
        </SafeAreaProvider>
    );
}

export default SentryNative.wrap(Root);