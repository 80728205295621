import { Button, SText } from "components"
import { colors } from "styles"
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { View } from "react-native";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useQuery } from "react-query";
import { QueryKeys } from "enums";
import { DocumentUtils, QueryUtils } from "utils";
import { DocumentModal } from "./Modals";
import { Icons } from "assets/icons";

enum InstanceByDocumentCategory {
    Hypotheken = 'mortgage',
    Polissen = 'policy',
    Schades = 'damage',
}

interface DocumentListProps {
    category: string
    id: number | string
}

export default forwardRef(({ category, id }: DocumentListProps, ref) => {

    const { styles: scopedStyles } = scopedResponsiveStyles();

    const { data: documents, refetch: refetchDocuments } = useQuery(QueryKeys.DOCUMENTS, QueryUtils.fetchDocuments);
    const [sharedDocuments, setSharedDocuments] = useState([]);
    const [showDocumentModal, setShowDocumentModal] = useState(undefined);
    const [removing, setRemoving] = useState(null);    
    useEffect(() => {
        if (documents && documents[category]) {
            setSharedDocuments(documents[category].filter(document => document[InstanceByDocumentCategory[category]]?.id === id));
        }
    }, [documents]);
    useImperativeHandle(ref, () => ({
        refresh: () => { refetchDocuments(); }
    }));

    const removeDocument = async (document: Document) => {
        setRemoving(document.id);
        const response = await DocumentUtils.deleteDocument(document);
        if (response.success == true) {
            setSharedDocuments(sharedDocuments.filter(doc => doc !== document));
            await refetchDocuments();
        }
        setRemoving(null);
    }
    
    const renderSharedItems = () => {
        return sharedDocuments.map((document: Document, index) => {
            return (
                <View
                    key={index + document.id}
                    style={scopedStyles('sharedDocumentButtonContainer')}
                >
                    <SText sm semibold primary>{document.name}</SText>
                    <View style={scopedStyles('sharedDocumentButtons')}>
                        {document.deletable ? (
                            <Button
                                loading={removing === document.id}
                                label={'#action_remove'}
                                type='error'
                                onPress={() => removeDocument(document)}
                                icon={(color) => <Icons.Delete_trash size={16} color={color} />}
                                style={{ marginRight: 8 }}
                            />
                        ) : (
                            <View></View>
                        )}
                        <Button
                            label={'#action_view'}
                            type='primary'
                            onPress={() => {
                                setShowDocumentModal(document);
                            }}
                            icon={(color) => <Icons.File size={16} color={color} />}
                        />
                    </View>

                </View>
            )
        })
    }

    return (
        <View>
            {sharedDocuments.length > 0 &&
                <View style={{ marginTop: 20 }}>
                    <SText md semibold primary>#shared_documents</SText>
                    <SText sm regular black style={{marginBottom: 12 }}>#removing_document</SText>
                    <View style={scopedStyles('sharedContainer')}>
                        {renderSharedItems()}
                    </View>
                </View>
            }
            {showDocumentModal &&
                <DocumentModal settings={{ ...showDocumentModal, hide: () => { setShowDocumentModal(undefined) } }} />
            }
        </View>
    );

});

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        sharedContainer: {
            marginBottom: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 20
        },
        sharedDocumentButtonContainer: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: colors.white,
            borderRadius: 8,
            paddingHorizontal: 8,
            paddingVertical: 8
        },
        sharedDocumentButtons: {
            flexDirection: 'row',
            alignItems: "center"
        },
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            sharedDocumentButtonContainer: {
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            sharedDocumentButtons: {
                marginTop: 8,
                width: '100%',
                justifyContent: 'space-between'
            },
        }
    }
)