import { BottomNavBar, Button, ContentScroller, SText } from "components";
import { DamageCreateModal } from "components/Modals";
import { useItemSize, useSignalItems, useStyle } from "hooks";
import { ModalContext } from "providers/ModalProvider";
import { useContext, useState } from "react";
import { Dimensions, View } from "react-native";
import Content from "./Content";



export default () => {

    const styles = useStyle();
    const itemSize = useItemSize();
    const { signalItems } = useSignalItems({ categorized: true, excludeFinishedUpload: true });
    const { showModal } = useContext(ModalContext);

    const [selectedNavItem, setSelectedNavItems] = useState('DAMAGES');
    const navItems = [{ title: 'schade', key: 'DAMAGES' }];

    return (
        <Content
            specificTopHeight={Dimensions.get('window').height * 0.3}
            title="#title_damages"
        >
            <View style={styles('fullscreen')}>
                <SText sm regular black>#undertitle_damages</SText>
                <View style={{ position: 'absolute', flexDirection: 'row', bottom: 30, width: '100%' }}>
                    <Button
                        label={'#action_damage'}
                        type='action'
                        onPress={() => { showModal(DamageCreateModal, {}, 'DamageCreateModal') }}
                    />
                </View>
            </View>

            <BottomNavBar navItems={navItems} handleNavItemSelect={(e) => { setSelectedNavItems(e.key) }} />

            <View style={[styles('fullscreen')]}>
                <ContentScroller style={{
                    justifyContent: signalItems?.[selectedNavItem]?.length > 0 ? 'flex-start' : 'center',
                    alignItems: signalItems?.[selectedNavItem]?.length > 0 ? 'flex-start' : 'center',
                }}>

                    {signalItems?.[selectedNavItem]?.length > 0 ? (

                        signalItems?.[selectedNavItem]?.map((item, key) => (
                            <View key={key} style={[
                                styles('bigMarginRight'),
                                styles('bigMarginBottom'),
                                {
                                    width: itemSize.width,
                                    height: itemSize.height,
                                    borderRadius: 8,
                                    overflow: 'hidden'
                                }
                            ]}>
                                {item}
                            </View>
                        ))

                    ) : (
                        <SText white md medium>#empty_screen_damages</SText>
                    )}

                </ContentScroller>
            </View>
        </Content>
    )
}
