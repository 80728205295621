import { Button, SText } from "components"
import { colors } from "styles"
import { useItemSize, useStyle } from "hooks";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { View } from "react-native";


interface moduleProps {
    title?: string
    children: React.ReactNode
    amount?: number,
    buttonValue: string,
    polis?: any,
    buttonAction?: () => void
}

export default ({ title, children, polis, buttonValue, buttonAction }: moduleProps) => {

    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const itemSize = useItemSize();

    const renderPolisNummer = () => polis ? <SText sm medium primary style={scopedStyles('pakket')}>{polis}</SText> : undefined;

    return (
        <View
            style={[
                styles('bigMarginRight'),
                styles('bigMarginBottom'),
                scopedStyles('container'),
                { width: itemSize.width - 1, height: itemSize.height - 1 }
            ]}
        >
            {renderPolisNummer()}

            {title &&
                <SText medium md primary style={scopedStyles('title')}>{title}</SText>
            }

            <View style={{ flex: 1, width: '100%' }}>
                {children}
            </View>

            <Button
                label={buttonValue}
                onPress={buttonAction}
                style={scopedStyles('button')}
                unclickable={!buttonAction}
                type='action'
            />

        </View>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        container: {
            padding: 20,
            paddingHorizontal: 10,
            backgroundColor: colors.white,
            borderRadius: 8,            
        },
        color: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: 30,
            height: '120%',
        },
        colorWeb: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '120%',
            height: 30
        },
        colorAlert: {
            backgroundColor: colors.error
        },
        titleContainer: {
            alignItems: "center",
            justifyContent: "space-between"
        },
        title: {
            marginTop: -5,
            marginBottom: 20
        },
        pakket: {
            marginTop: -10
        },
        badge: {
            marginRight: 2
        },
        button: {
            position: 'absolute',
            width: '60%',
            height: 34,
            left: '20%',
            bottom: -17,
            zIndex: 99
        }
    }, {
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
        button: {
            bottom: -16
        }
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        container: {
            marginBottom: 40,
        }
    }
}
)