import { Icons } from "assets/icons"
import { BottomNavBar, Button, ContentScroller, SText } from "components"
import BaseItem from "components/BaseItem"
import { AddPolicyModal, DamageCreateModal, EditModal, PolicyModal } from "components/Modals"
import PolicyPie from "components/PolicyPie"
import { PolicyCategories, PolicyTypes, QueryKeys, Screen } from 'enums'
import { useItemSize, useLayoutMargin, useSorted, useStyle } from "hooks"
import { Policy } from "models"
import { ModalContext } from "providers/ModalProvider"
import { useContext, useEffect, useState } from "react"
import { Dimensions, Image, Platform, Pressable, View } from "react-native"
import { useQuery } from "react-query"
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles"
import { Content } from 'screens/home'
import { colors } from "styles"
import { QueryUtils, ValutaUtils } from "utils"


const PolicyIcons = {
    Aanhanger: Icons.Trailer,
    Auto: Icons.Car,
    Bromfiets: Icons.Scooter,
    Caravan: Icons.Caravan,
    Garage: Icons.Garage,
    Motorrijtuigen: Icons.Motorbike,
    Rijwiel: Icons.Bicycle,
    Luchtvaart: Icons.Aviation,

    Aansprakelijkheid: Icons.Checklist,
    Ziekte: Icons.Life,
    Ongeval: Icons.Emergency,

    Brand: Icons.Fire,
    Glas: Icons.Glass,
    Inboedel: Icons.Household,
    Kostbaarheden: Icons.Valuables,
    Opstal: Icons.Home,

    Rechtsbijstand: Icons.Legal_counsel,
    Lenen: Icons.Loan,
    Pensioen: Icons.Retired,
    Persoon: Icons.Person,
    Hypotheken: Icons.Home,
    Pakket: Icons.More,

    'Motor/scooter': Icons.Scooter,
    'Buitenland, reizen': Icons.Travel_abroad,
    '(Huis) Elektronica': Icons.Electronics,
    'Opstal/inboedel': Icons.Home_household_insurence,
    'Scheepvaart en transport': Icons.Ship,
    'Diverse geldelijke verliezen': Icons.Losses,
    'Krediet en borgtocht': Icons.Bank_account,
}

const policyIconType = (policy: Policy) => {

    const { branche_group_name: brancheGroup, view_group_name: viewGroup } = policy;
    let icon = PolicyIcons[brancheGroup];


    if (brancheGroup == PolicyTypes.VOERTUIG || brancheGroup == PolicyTypes.BRAND) {

        icon = PolicyIcons[viewGroup];

    } else if (brancheGroup == PolicyTypes.ONGEVAL || viewGroup == 'Ongevallen en Ziekte') {

        icon = (viewGroup.includes('Ziek')) ? PolicyIcons['Ziekte'] : PolicyIcons['Ongeval'];

    } else if (brancheGroup == PolicyTypes.LEVEN) {

        if (viewGroup == 'Banksparen') {

            icon = PolicyIcons['Lenen'];

        } else if (viewGroup.toLowerCase().includes('pensioen')) {

            icon = PolicyIcons['Pensioen'];

        } else {

            icon = PolicyIcons['Persoon'];

        }

    }

    return icon || Icons.More;

};

const orderPolicies = (policies: Policy[]) => {

    const bundle = {}
    //push package_children and standalone policies
    policies.map(policy => {
        if (policy.parent_id) {
            (!bundle[policy.parent_id]) ? bundle[policy.parent_id] = [policy] : bundle[policy.parent_id].push(policy);
        } else if (!policy.is_package) {
            bundle[policy.id] = policy;
        }
    })
    //sort and flatten
    return Object.values(bundle).sort((a, b) => Array.isArray(a) ? -1 : 1).flat();
}

const shortDescription = ({ descriptions, view_group_name }: { descriptions: string[], view_group_name: string }) => {

    if (!descriptions) return '';
    if (typeof descriptions == 'object') descriptions = Object.values(descriptions);

    if (view_group_name == 'Rijwiel') return descriptions[0] ? descriptions[0].trim() : 'Rijwiel';

    //verzekerd persoon
    let hasInsuredPerson = descriptions.reduce((acc: string, description: string) => (description && description.search('Verzekerd persoon: ') >= 0) ? description : acc, '');
    if (hasInsuredPerson) {
        hasInsuredPerson = hasInsuredPerson.replace('Verzekerd persoon: ', '#insured_person ');
        return hasInsuredPerson.replace(/[0-9]/g, '').replace(/\//g, '').trim();
    }

    //verzekerd adres
    const addressRegex = /([0-9]{4}[a-z]{2})/gim;
    let hasAddress = descriptions.reduce((acc: string, description: string) => (description && description.search(addressRegex) >= 0) ? description : acc, '');
    if (hasAddress) return hasAddress;

    //kenteken / merk
    const kentekenRegex = /^((([0-9]{3}(?![0-9]))|([a-z]{3}(?![a-z])))|(([0-9]{1,2})|([a-z]{1,2}))|-){6,}/gim;
    const hasLicense = descriptions.reduce((acc: string, description: string) => (description && description.search(kentekenRegex) >= 0 && !acc) ? description : acc, '');
    if (hasLicense && hasLicense.includes('-')) return hasLicense;

    return '';

}









export default () => {

    const largeScreen = Dimensions.get('window').width > 768;
    const [pieSize, setPieSize] = useState(null);
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { data: policies, isLoading: loadingPolicies } = useQuery(QueryKeys.POLICY, QueryUtils.fetchPolicy);
    const { data: sortedPolicies, handleSearchQuery, setData } = useSorted();
    const [category, setCategory] = useState(PolicyCategories.tenant);
    const { showModal } = useContext(ModalContext);
    const totaal = policies?.reduce((acc, val) => acc + val.monthly_term_amount, 0);

    const styles = useStyle();
    const itemSize = useItemSize();
    const margin = useLayoutMargin();

    useEffect(() => {
        !loadingPolicies && setData(orderPolicies(policies));
    }, [policies]);


    const handleNavItem = (item) => setCategory(item.key);

    const renderPolicyItems = () => {

        return policies?.length == 0 ? (
            <View style={[{ height: '100%', flex: 1, justifyContent: "center", alignItems: "center", flexDirection: 'row' }, styles('bigMarginBottom'), styles('bigMarginRight')]}>
                <SText white md medium>#empty_screen_pre #empty_screen_policies #empty_screen_post</SText>
            </View>
        ) : (
            sortedPolicies?.map((policy: Policy, index) => {
                let cat = false;
                if (category == PolicyCategories.tenant && policy.mutation_code == 9) cat = true;
                if (category == PolicyCategories.other && policy.mutation_code != 9) cat = true;
                return (policy.is_package || cat) ? false : policyItem(policy, index);

            })?.filter(policy => policy)
        );

    }

    const policyItem = (policy: Policy, index: number | string) => {

        const Icon = policyIconType(policy);       
        const shortDesc = shortDescription(policy); 

        return (
            <Pressable
                key={index}
                onPress={() => showModal(PolicyModal, { policy, Icon }, 'PolicyModal')}
                style={[Platform.OS == 'ios' && { marginBottom: margin }]}
            >
                <BaseItem
                    buttonValue={ValutaUtils.valutaString(policy.monthly_term_amount) + ' #p/m'}
                    buttonAction={() => showModal(PolicyModal, { policy, Icon }, 'PolicyModal')}
                    polis={policy.contract_number}
                >
                    <View style={scopedStyles('container')}>

                        <View style={scopedStyles('dynamic-container')}>
                            <Icon
                                size={itemSize.height < itemSize.width ? 40 : itemSize.width * 0.4}
                                color={colors.primary}
                            />
                            <View style={{minWidth: itemSize.height < itemSize.width ? '70%' : 'auto'}}>
                                <SText bold md primary center style={{ marginTop: itemSize.height < itemSize.width ? 0 : 20, marginBottom: 4 }}>{policy.agency}</SText>
                                <SText sm regular grey center style={{ marginBottom: 2 }}>{policy.view_group_name}</SText>
                                {shortDesc && <SText sm regular grey center style={{ marginBottom: 2 }}>{shortDesc}</SText>}
                            </View>
                        </View>

                    </View>

                </BaseItem>
            </Pressable>
        )
    }


    const renderPie = () => {
        return (
            <>
                {(largeScreen) &&
                    <View style={{
                        width: 150,
                        top: '4%',
                        alignSelf: 'flex-end',
                        marginRight: 300
                    }}>
                        <SText regular sm black>#hover</SText>
                        <Image
                            style={{
                                position: 'absolute',
                                width: 50,
                                height: 50,
                                marginTop: '15%',
                                marginLeft: '80%'
                            }}
                            source={require('assets/images/arrow.png')}
                        />
                    </View>
                }

                <View
                    style={[scopedStyles('pieChart'), { width: pieSize }]}
                    onLayout={(e) => setPieSize(e.nativeEvent.layout.height)}
                >
                    <PolicyPie dark opklik style={{ width: '100%', height: '100%' }} />
                </View>
            </>
        )
    }

    return (
        <Content
            title="#title_policy"
        >
            <View style={styles('fullscreen')}>

                <SText sm regular black>#undertitle_policies</SText>

                {totaal > 0 && renderPie()}

                <View style={[scopedStyles('buttonContainer'), !largeScreen && styles('bigMarginBottom')]}>
                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label="#action_edit_long"
                            type='action'
                            onPress={() => showModal(EditModal, { screen: Screen.VERZEKERINGEN }, 'EditModal')}
                            style={scopedStyles('button')}
                        />
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label="#action_add_insurance"
                            type='secondary'
                            onPress={() => showModal(AddPolicyModal, {}, 'AddPolicyModal')}
                            style={scopedStyles('button')}
                        />
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Button
                            disabled={policies && policies?.length == 0}
                            label="#action_damage"
                            type='secondary'
                            onPress={() => showModal(DamageCreateModal, {}, 'DamageCreateModal')}
                            style={[scopedStyles('button'), !largeScreen && { minHeight: 37 }]}
                        />
                    </View>
                </View>
            </View>

            <BottomNavBar
                navItems={[
                    { title: '#policies_tenant', key: PolicyCategories.tenant },
                    { title: '#policies_other', key: PolicyCategories.other }
                ]}
                handleNavItemSelect={handleNavItem}
                handleSearchQuery={handleSearchQuery}
                noSearch={policies && policies?.length == 0}
            />

            <View style={[styles('fullscreen')]}>

                <ContentScroller>
                    {renderPolicyItems()}
                </ContentScroller>

            </View>
        </Content>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        container: {
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
        },
        'dynamic-container': {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center'
        },
        buttonContainer: {
            position: "absolute",
            bottom: 0,
            flexDirection: 'row',
        },
        button: {
            marginRight: 8
        },
        pieChart: {
            position: 'absolute',
            height: '110%',
            right: -20,
            marginTop: '-2%'
        },
        coverage: {
            flexDirection: 'row',            
            justifyContent: 'space-between',
        }
    }, {
    [maxSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)]: {
        container: {
            flex: 0.85,
            marginTop: 8
        }
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
        container: {
            flex: 0.75,
        },        
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        pieChart: {
            position: 'absolute',
            width: Dimensions.get('window').height * 0.35,
            height: Dimensions.get('window').height * 0.35,
            left: (Dimensions.get('window').width * 0.5) - (Dimensions.get('window').height * 0.2),
            top: Dimensions.get('window').height * 0.05
        },
        'dynamic-container': {
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 22,
        },
        buttonContainer: {
            width: '100%',
            flexWrap: 'wrap'
        },
        button: {
            paddingRight: 0,
            marginVertical: 8
        },
        policyPackage: {
            flexGrow: 1,
            flexDirection: "column",
            height: 'auto',
            width: '100%',
            paddingRight: 0,
            marginRight: 0,
            paddingVertical: 30,
            marginBottom: 30,
            borderRadius: 12
        },
        policyItem: {
            width: Dimensions.get('window').width * 0.9,
            height: 'auto',
            minHeight: Dimensions.get('window').height * 0.35,
            marginVertical: 20,
            marginLeft: 0
        },
    }
}
)