import { Button, CloseButton, IconTitle } from "components";
import SharedDocumentList from "components/SharedDocumentList";
import SText from "components/SText";
import { DateTimeFormat } from "enums";
import { useStyle } from "hooks";
import { Policy } from "models";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { ReactElement, useContext, useRef } from "react";
import { Dimensions, Modal, Platform, Pressable, ScrollView, View } from "react-native";
import * as Animatable from 'react-native-animatable';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { ValutaUtils } from "utils";


export default ({ settings }) => {

    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { open, hideModal } = useContext(ModalContext);
    const largeScreen = Dimensions.get('window').width > 768;

    const policy: Policy = settings.policy;
    const startDate = moment(policy.start_date).format(DateTimeFormat.display_date);
    const totalCosts = policy.is_package ? policy.children : [policy];
    const totalInsured = policy.is_package ? policy.children.map((child: Policy) => child.insured_amounts).flat(2) : [policy.insured_amounts];
    const totalDescriptions = policy.is_package ? [] : (typeof policy.descriptions == 'object') ? Object.values(policy.descriptions) : policy.descriptions;
    if (policy.is_package) {
        const set = {};
        policy.children.map((child: Policy) => child.descriptions).flat().map((item: string) => { set[item] = true });
        Object.keys(set).map(item => totalDescriptions.push(item));
    }

    const sharedDocumentListRef = useRef();

    const renderForm = () => {
        return (
            <View style={scopedStyles('container')}>

                <View style={{ flex: 1, marginBottom: 30 }}>

                    <IconTitle
                        Icon={settings.Icon}
                        title={policy.agency}
                    >
                        <SText lg semibold primary>{ValutaUtils.valutaString(policy.monthly_term_amount)}</SText>
                        <SText md medium primary> #p/m</SText>
                    </IconTitle>


                    {renderDetails()}
                    {renderCosts()}
                    
                    <SharedDocumentList category="Polissen" id={policy.id} ref={sharedDocumentListRef}/>

                </View>


                <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>

                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label='#close'
                            type={"action"}
                            onPress={() => hideModal()}
                        />
                    </View>

                </View>

            </View >
        )
    }

    const renderDetails = () => {
        const showInsuredAmounts = totalInsured.reduce((acc, v) => typeof v == 'object' ? acc + Object.keys(v).length : acc, 0) > 0;

        return (
            <View style={{ justifyContent: "space-evenly" }}>

                <SText md semibold primary style={{ marginBottom: 12 }}>#data</SText>

                <View style={scopedStyles('category')}>
                    <SText sm medium black style={{ width: 150 }}>#description</SText>
                    <View style={{ marginLeft: largeScreen ? 8 : 0, flex: 1 }}>
                        {totalDescriptions.map((item, index) => {
                            const removeHTMLTags = item?.replace(/<.+?(?=>)>.+?(?=<)<\/.+?(?=>)>/gim, '');
                            return <SText key={'cat' + index} sm grey medium>● {removeHTMLTags}</SText>;
                        })}
                    </View>
                </View>

                {showInsuredAmounts &&
                    <View style={scopedStyles('category')}>
                        <SText sm medium black style={{ width: 150 }}>#coverage</SText>
                        <View style={{ marginLeft: largeScreen ? 8 : 0, flex: 0.9, maxWidth: largeScreen ? 350 : 999 }}>
                            {totalInsured.map((item) => {
                                return Object.entries(item).map((entry, index) => {
                                    return (
                                        <View
                                            key={'te' + index}
                                            style={[{
                                                flexDirection: 'row',
                                                justifyContent: "space-between"
                                            }]}>
                                            <SText capitalize oneline sm grey medium style={{ flex: 1 }}>● {entry[0]?.replace('Dekking ', '') || '#unknown'}</SText>
                                            <SText sm grey medium style={{ marginLeft: 20 }}>{entry[1]?.replace(',00', ',‐ ') || '#unknown'}</SText>
                                        </View>
                                    )
                                })
                            })}
                        </View>
                    </View>
                }

                {/* loopt af */}
                <View style={scopedStyles('category')}>
                    <SText sm medium black style={{ width: 150 }}>#start_date</SText>
                    <SText sm grey medium style={{ marginLeft: largeScreen ? 8 : 0 }}>{startDate}</SText>
                </View>

            </View>
        );
    };

    const renderSharedDocuments = () => {
        return (
            <View style={{ marginTop: 20, justifyContent: "space-evenly" }}>

                <SText md semibold primary style={{ marginBottom: 12 }}>#shared_documents</SText>
                <SharedDocumentList category="Polissen" id={policy.id} ref={sharedDocumentListRef}/>

            </View>
        );
    };

    const renderCosts = () => {
        const costs: ReactElement[] = [];
        totalCosts.map((item: Policy) => {
            costs.push(<View key={item.id + 'c' + 1} style={scopedStyles('nItem2')}><SText sm regular black>{item.name}</SText></View>);
            costs.push(<View key={item.id + 'c' + 2} style={scopedStyles('nItem')}><SText sm regular black>{ValutaUtils.valutaString(item.monthly_term_amount)}</SText></View>);
            costs.push(<View key={item.id + 'c' + 3} style={scopedStyles('nItem')}><SText sm regular black>{ValutaUtils.valutaString(item.monthly_term_amount * 12)}</SText></View>);
        });

        return (
            <View style={{ marginTop: 20 }}>

                <SText md semibold primary style={{ marginBottom: 12 }}>#cost</SText>

                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                    <View style={[scopedStyles('tItem2'), { borderTopLeftRadius: 4 }]}><SText sm semibold black>#policy_number</SText></View>
                    <View style={scopedStyles('tItem')}><SText sm semibold black>#per_month</SText></View>
                    <View style={[scopedStyles('tItem'), { borderTopRightRadius: 4 }]}><SText sm semibold black>#per_year</SText></View>

                    {costs}

                </View>

            </View>
        )        
    }






    return (
        <Modal
            visible={open}
            transparent
        >
            <Pressable
                style={styles('fullscreen')}
                onPress={hideModal}
            >
                <ScrollView
                    style={scopedStyles('background')}
                    contentContainerStyle={{ alignItems: "center", flexGrow: 1 }}
                >

                    <Animatable.View
                        style={[scopedStyles('form')]}
                        animation={'zoomIn'}
                        duration={200}
                    >
                        <View style={scopedStyles('formDecoration')} />

                        <CloseButton onPress={hideModal} />

                        <Pressable //this pressable is to prevent a click/tap on the form to close through the pressable further up
                            onPress={() => { }}
                            style={[
                                {
                                    flex: 1,
                                    flexDirection: largeScreen ? 'row' : 'column-reverse',
                                    justifyContent: "space-between"
                                },
                                Platform.OS === 'web' && { cursor: 'default' }
                            ]}
                        >

                            {renderForm()}

                        </Pressable>
                    </Animatable.View>
                </ScrollView>
            </Pressable>
        </Modal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        background: {
            backgroundColor: 'rgba(0,0,0,0.2)',
            width: '100%',
            flex: 1
        },
        form: {
            width: '50%',
            height: 'auto',
            minHeight: 0,
            minWidth: 485,
            marginTop: 120,
            backgroundColor: colors.background,
            borderRadius: 8,
            overflow: 'visible'
        },
        formDecoration: {
            position: 'absolute',
            height: 100,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: colors.formDecoration,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
        container: {
            flex: 1,
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        titel: {
            marginVertical: 30
        },
        noteText: {
            backgroundColor: colors.lightgrey,
            flexGrow: 1,
            marginBottom: 30,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
            borderColor: colors.grey,
            borderWidth: 1
        },
        category: {
            flexDirection: 'row',
            marginBottom: 12
        },
        tItem: {
            width: '25%',
            paddingVertical: 12,
            paddingLeft: 8,
            backgroundColor: colors.formDecoration
        },
        nItem: {
            width: '25%',
            paddingVertical: 12,
            paddingLeft: 8,
            backgroundColor: colors.white
        },
        tItem2: {
            width: '50%',
            paddingVertical: 12,
            paddingLeft: 8,
            backgroundColor: colors.formDecoration
        },
        nItem2: {
            width: '50%',
            paddingVertical: 12,
            paddingLeft: 8,
            backgroundColor: colors.white
        },
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            form: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            form: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            container: {
                paddingTop: 20,
                paddingHorizontal: 12
            },
            category: {
                flexDirection: 'column',
                marginBottom: 12
            },
            titel: {
                marginBottom: 20,
                marginTop: 0
            },
        }
    }
)
