import { MortgageUploadModal } from "components/Modals";
import SText from "components/SText";
import { CustomerViewableObjects, QueryKeys } from "enums";
import { Mortgage } from "models";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import { useContext } from "react";
import { Text, View } from "react-native";
import { useQuery } from "react-query";
import { CustomerUtils, QueryUtils } from "utils";
import BaseModule from "./BaseModule";


export default ({ amount, filename, mortgage, warning }: { amount: number, filename: string, mortgage: Mortgage, warning: boolean }) => {

    const { showModal } = useContext(ModalContext);
    const { triggerLoading } = useContext(StoreContext);
    const { refetch: refetchMortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);

    let moduleText = undefined;
    switch (amount) {
        case 0:
            moduleText = (
                <SText regular sm black>#upload_empty</SText>
            );
            break;
        case 1:
            moduleText = (
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    <Text>
                        <SText regular sm black>#upload_pre</SText>
                        <SText semibold sm black> {filename} </SText>
                        <SText regular sm black>#upload_post</SText>
                    </Text>
                </View>
            );
            break;
        default:
            moduleText = (
                <Text>
                    <SText regular sm black>{`#upload_pre_multi`}</SText>
                    <SText semibold sm black> {amount} </SText>
                    <SText regular sm black>{`#upload_post_multi`}</SText>
                </Text>
            );
            break;
    }

    return (
        <BaseModule
            disabled={amount == 0}
            warning={warning}
            title={amount === 1 ? filename : '#title_upload'}
            underTitle={String(mortgage.hypotheeknummer)}
            text={moduleText}
            buttonValue='#action_documenten_leveren'
            buttonAction={async () => {
                showModal(MortgageUploadModal, { filename, amount, mortgage }, 'MortrgageUpoloadModal')
                if (!mortgage.seen_by_customer) {
                    mortgage.seen_by_customer = true;
                    await CustomerUtils.view(CustomerViewableObjects.MORTGAGE, mortgage.id);
                    refetchMortgages();
                    triggerLoading();
                }
            }}
        />
    )

}
