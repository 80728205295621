import { Icons } from "assets/icons";
import { Button, Item, SText } from "components";
import { DateTimeFormat, QueryKeys } from 'enums';
import { MortgageProgress } from "models/MortgageProgress";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, Platform, Pressable, Text, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { DocumentUtils, QueryUtils } from "utils";
import BaseModal from "./BaseModal";
import IosUploadSelectModal from "./IosUploadSelectModal";
import ShowMessageModal from "./ShowMessageModal";
import moment from "moment";
import SharedDocumentList from "components/SharedDocumentList";

enum uploadStates {
    pickDocument = 'pickDocument',
    confirmDocument = 'confirmDocument',
    uploadDocument = 'uploadDocument',
    none = 'none'
}

export default ({ settings }) => {

    //styles
    const { width } = useWindowDimensions();    
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { data: mortgages, refetch: refetchMortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { triggerLoading } = useContext(StoreContext);
    const { hideModal } = useContext(ModalContext);
    const largeScreen = Dimensions.get('screen').width > 768;

    //load mortgages and sort progress
    const mortgage = mortgages?.filter(mortgage => mortgage.id == settings?.mortgage?.id)[0];
    const [sortedProgress, setSortedProgress] = useState<MortgageProgress[]>([]);
    useEffect(() => {
        setSortedProgress(mortgage?.progress ? mortgage.progress.sort((a, b) => a.received - b.received).filter((mortgageProgress) => !mortgageProgress.completed) : []);
    }, [mortgage])

    
    
    //the size of the upload items
    const commentLength = useRef(100);
    useEffect(() => {
        if (!largeScreen) commentLength.current = 32;
    }, [largeScreen])
    const [showMessageModal, setShowMessageModal] = useState(undefined);

    //uploading files
    const [uploadState, setUploadState] = useState(uploadStates.none);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [iosupload, setIosUpload] = useState(false);
    const [uploadedDocs, setUploadedDocs] = useState({});
    const sharedDocumentListRef = useRef();




    const upload = async (mortgageProgress = undefined, iosMethod = undefined, reupload = undefined) => {

        const state = reupload ? uploadStates.none : uploadState;

        switch (state) {

            case uploadStates.uploadDocument:

                return;

            case uploadStates.none:

                if (Platform.OS == 'ios' && !iosMethod) {
                    setIosUpload(mortgageProgress);
                    return;
                }

                const pickMethod = Platform.select({
                    ios: async () => {
                        if (iosMethod == 'image') {
                            return await DocumentUtils.pickImage();
                        } else {
                            return await DocumentUtils.pickDocument();
                        }
                    },
                    default: async () => {
                        return await DocumentUtils.pickDocument();
                    }
                })

                const pickedDocument = await pickMethod();
                if (!pickedDocument) return;

                setIosUpload(false);
                setUploadDocument({ ...pickedDocument, progressId: mortgageProgress.id, progressName: mortgageProgress.name });
                setUploadState(uploadStates.uploadDocument);

                const extension = pickedDocument.name.split('.');
                const filename = mortgageProgress.name + '.' + extension[extension.length - 1];

                DocumentUtils.uploadDocument(pickedDocument, filename, { mortgageProgressId: mortgageProgress.id })
                    .then(async ({ data }) => {

                        if (data.success) {
                            await new Promise(resolve => {
                                setTimeout(async () => {
                                    await refetchMortgages();                                    
                                    sharedDocumentListRef.current?.refresh();
                                    resolve(true);
                                }, 1500)
                            });
                            triggerLoading();
                        }

                        setUploadedDocs({
                            ...uploadedDocs,
                            [mortgageProgress.id]: {
                                filename: pickedDocument.name,
                                success: data.success
                            }
                        });

                        setUploadState(uploadStates.none);
                        setUploadDocument(null);


                    })
                break;

        }

    }


    const renderMortgageInfo = () => (
        <View style={{ marginHorizontal: '1%', marginTop: 20 }}>
            <SText md semibold primary style={{ marginBottom: 12 }}>#data</SText>

            <View style={scopedStyles('category')}>
                <SText sm medium black style={{ width: 150 }}>#mortgage_number</SText>
                <View style={{ marginLeft: width > 768 ? 8 : 0, flex: 1 }}>
                    <SText sm grey medium>{mortgage?.hypotheeknummer}</SText>
                </View>
            </View>

            <View style={scopedStyles('category')}>
                <SText sm medium black style={{ width: 150 }}>#status</SText>
                <View style={{ marginLeft: width > 768 ? 8 : 0, flex: 1 }}>
                    <SText sm grey medium>{mortgage?.status_description}</SText>
                </View>
            </View>
        </View>
    );


    const renderUploadItems = () => {
        const result: any = [];

        sortedProgress && sortedProgress.map((mortgageProgress: MortgageProgress, dIndex) => {
            result.push(
                <View
                    key={dIndex}
                    style={[
                        scopedStyles('uploadItem'),
                        { width: '44%' }
                    ]}>

                    <SText sm bold primary style={{ marginBottom: 8 }}>{mortgageProgress.name}</SText>

                    <Item
                        style={{ width: '100%', flex: 0.9 }}
                    >
                        {renderItem(mortgageProgress, 'd' + dIndex)}
                    </Item>
                </View>
            );
        })

        return result;
    }

    const renderItem = (mortgageProgress) => {

        if (uploadState == uploadStates.uploadDocument && uploadDocument.progressId === mortgageProgress.id) {

            return (
                <View style={scopedStyles('uploadIcon')}>
                    <ActivityIndicator
                        color={colors.secondary}
                        size={32}
                    />
                </View>
            );

        } else if (uploadedDocs[mortgageProgress.id]) {

            const uploadedSuccesfull = uploadedDocs[mortgageProgress.id].success;

            return (
                <View style={scopedStyles('uploadIcon')}>
                    <View style={{
                        backgroundColor: uploadedSuccesfull ? colors.action : colors.error,
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        {uploadedSuccesfull ?
                            <Icons.Check size={25} color={colors.white} />
                            :
                            <Icons.Delete size={25} color={colors.white} />
                        }
                    </View>

                    <Pressable disabled={uploadState == uploadStates.uploadDocument} onPress={() => upload(mortgageProgress)} >
                        <SText xs regular black style={{ marginTop: 2, textDecorationLine: 'underline' }} >#uploads_retry</SText>
                    </Pressable>
                </View>
            );

        } else {

            return (mortgageProgress.received != true) ? (
                <>
                    <Pressable
                        disabled={uploadState == uploadStates.uploadDocument}
                        style={scopedStyles('uploadIcon')}
                        onPress={() => upload(mortgageProgress)}
                    >
                        <Icons.File_upload size={32} color={colors.secondary} />
                    </Pressable>

                    <Pressable
                        disabled={mortgageProgress.comment?.length <= commentLength.current}
                        style={[
                            { position: 'absolute', bottom: 8 },
                            Platform.OS == 'web' ? {
                                wordBreak: "all", width: '98%', left: '1%'
                            } : {
                                width: '110%', left: '3%'
                            }
                        ]}
                        onPress={() => setShowMessageModal({ 
                            message: mortgageProgress.comment, 
                            date: moment(mortgageProgress.updated_at).format(DateTimeFormat.display_date), 
                            title: mortgageProgress.name 
                        })}
                    >
                        <Text>
                            <SText noTranslate sm regular grey
                                url={mortgageProgress.comment?.length > commentLength.current}                            
                            >
                                {mortgageProgress.comment?.substring(0, commentLength.current)}{mortgageProgress.comment?.length > commentLength.current ? '... ' : ''}
                            </SText>
                            {mortgageProgress.comment?.length > commentLength.current && <SText sm semibold primary url>#action_read_more</SText>}
                        </Text>
                    </Pressable>
                </>
            ) : (
                <View
                    style={scopedStyles('uploadIcon')}
                >

                    <Icons.Read_only size={32} color={colors.secondary} />
                    <SText sm medium primary center style={{ marginTop: 2 }}>#advisor_judge</SText>

                    <Pressable disabled={uploadState == uploadStates.uploadDocument} onPress={() => upload(mortgageProgress)} >
                        <SText xs regular black center style={{ marginTop: 2, textDecorationLine: 'underline' }} >#uploads_retry</SText>
                    </Pressable>
                </View>
            )

        }

    }

    


    return (
        <BaseModal style={scopedStyles('modal')}>

            <SText lg regular primary style={{ margin: '1%', marginTop: 20, marginBottom: 12 }}>{'#mortgage_requested'}</SText>

            {renderMortgageInfo()}

            {mortgage?.openProgress > 0 &&
                <View style={{ marginTop: 20 }}>
                    <SText md semibold primary style={{ marginLeft: '1%', marginBottom: 8 }}>#documents #action_upload</SText>

                    <View style={scopedStyles('uploadContainer')}>
                        {renderUploadItems()}
                    </View>
                </View>
            }

            <View style={{ marginLeft: '1%' }}>
                <SharedDocumentList category="Hypotheken" id={mortgage.id} ref={sharedDocumentListRef}/>
            </View>

            <View style={{ flex: 1 }}></View>

            <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>

                <View style={{ flexDirection: "row" }}>
                    <Button
                        label='#close'
                        type={"action"}
                        onPress={() => hideModal()}
                    />
                </View>

            </View>

            {showMessageModal &&
                <ShowMessageModal 
                    settings={{ ...showMessageModal, hide: () => { setShowMessageModal(undefined) } }}
                    style={{marginTop: 220, width: largeScreen ? '45%' : '95%'}}
                />
            }

            {iosupload &&
                <IosUploadSelectModal
                    close={() => setIosUpload(false)}
                    onPick={(type: string) => {
                        upload(iosupload, type);
                    }}
                />
            }
        </BaseModal>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '40%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        uploadContainer: {
            width: '100%',
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: "space-between"
        },
        category: {
            flexDirection: 'row',
            marginBottom: 12
        },
        uploadItem: {
            height: 250,
            margin: '1%',
            width: '50%',
            backgroundColor: colors.lightgrey
        },
        uploadMessage: {
            width: '90%',
            height: '30%',
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center'
        },
        uploadIcon: {
            position: "absolute",
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
        },
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            modal: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            container: {
                flex: 1
            },
            uploadItem: {
                marginBottom: 12,
                marginHorizontal: 0
            },
            uploadIcon: {
                position: "relative",
                margin: 0
            },
            category: {
                flexDirection: 'column',
                marginBottom: 12
            },
        }
    }
)